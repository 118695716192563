import type { Pinia } from 'pinia'
import { defineNuxtPlugin } from '#app'
import { useCartStore } from '~/stores/cart'

/**
 * Load the cart for the curent session.
 */
export default defineNuxtPlugin((nuxtApp) => {
  const cart = useCartStore(nuxtApp.$pinia as Pinia)
  if (!cart) {
    return
  }

  cart.loadCart()
})

import validate from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import navigate_45from_45error_45global from "/builds/bazinga/pharmasuisse/frontend/middleware/navigateFromError.global.ts";
import manifest_45route_45rule from "/builds/bazinga/pharmasuisse/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  navigate_45from_45error_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "anonymous-only": () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/middleware/anonymousOnly.ts"),
  "authenticated-only": () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/middleware/authenticatedOnly.ts"),
  "signup-flow": () => import("/builds/bazinga/pharmasuisse/frontend/sites/pharmasuisse/middleware/signupFlow.ts")
}
<template>
  <nuxt-link :to="{ name: 'cart' }" class="relative meta-nav-link">
    <SpriteSymbol name="cart" class="lg:!m-0" />
    <span class="lg:hidden">{{ $texts('cart', 'Warenkorb') }}</span>
    <ClientOnly>
      <div
        v-if="count > 0"
        class="absolute -top-5 left-10 z-10 bg-green text-white w-15 h-15 rounded text-center font-semibold text-xs flex items-center justify-center"
      >
        {{ count }}
      </div>
    </ClientOnly>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { useCartStore } from '~/stores/cart'
import type { PageLanguage } from '#language-negotiation/language'

defineProps({
  language: {
    type: String as PropType<PageLanguage>,
    default: '',
  },
})

const store = useCartStore()

const count = computed(() => {
  return store.count
})
</script>
